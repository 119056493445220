<template>
  <div class="roomdetailbox">
    <CompHeader title="Roll" />
    <div class="center">
      <div class="topinfo">
        <div class="roomid">房间ID:{{ roomdata.id }}</div>
        <div class="infocard">
          <div class="tophead">
            <div class="lbox">
              <img :src="roomdata.user?.avatar" alt="" />
            </div>
            <div class="rbox">
              <div class="infotext">{{ roomdata.name }}</div>
              <div class="infonum">
                <span>加入人数:{{ roomdata.join_number }}</span>
              </div>
            </div>
            <div class="joinbtnbox" v-if="roomdata.status != 1">
              <div class="joinbtn" @click="joinroom">加入</div>
            </div>
          </div>
          <div class="contenttext">活动描述:{{ roomdata.describe }}</div>
          <div class="contenttime">活动时间:{{ roomdata.end_time }}前截止</div>
        </div>
        <div class="goodstips">
          <div class="tipsitem">
            <span>奖品数量:{{ roomdata.awards?.length }}</span>
          </div>
          <div class="tipsitem">
            <span>奖品总价值:{{ roomdata.award_bean }}</span>
          </div>
        </div>
      </div>
      <div class="listbox">
        <div class="changetype">
          <div
            class="changeitem"
            v-for="(item, i) in cahngelist"
            :key="i"
            :class="{ acchangeitem: item.id == acchangeindex }"
            @click="changeclick(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="userlist" v-show="acchangeindex == 1">
          <div class="usersitem" v-for="(item, i) in roomdata.users" :key="i">
            <div style="width: 100%">
              <div class="userhead">
                <img :src="item.user?.avatar" alt="" />
              </div>
              <div class="username">
                {{ item.user?.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="goodslist" v-show="acchangeindex == 2">
          <div class="goodsitem" v-for="(item, i) in roomdata.awards" :key="i">
            <div class="goldbox" v-if="item.box_record.is_purse == 1">
              <img src="../../assets/newImages/moeny-pile.png" alt="" />
            </div>
            <div v-else>
              <div class="goodstop">{{ item.box_record.dura_alias }}</div>
              <div class="imgbox">
                <img :src="item.box_record.cover" alt="" />
              </div>
              <div class="goodsname">{{ item.box_record.name }}</div>
              <div class="pricebox">
                 <CompCoin imgName="coin-z" :num="item.bean" />
              </div>
            </div>
          </div>
        </div>
        <div class="getgoodslist" v-show="acchangeindex == 3">
          <div v-if="roomdata.status == 1">
            <div
              class="getgoodsitem"
              v-for="(item, i) in roomdata.awards"
              :key="i"
              :class="{
                isluckybox: user.id == item.box_record.user?.id,
              }"
            >
              <div class="lbox" v-if="item.box_record.is_purse == 1">
                <img src="../../assets/newImages/moeny-pile.png" alt="" />
              </div>
              <div class="lbox" v-else>
                <img :src="item.box_record.cover" alt="" />
                <div>
                  <div class="goodsname">{{ item.box_record.name }}</div>
                  <div class="goodsname">{{ item.box_record.dura_alias }}</div>
                  <div class="goodsprice">
                   <CompCoin imgName="coin-z" :num="item.bean" />
                  </div>
                </div>
              </div>
              <div
                class="rbox"
                v-show="roomdata.user_id !== item.box_record.user?.id"
              >
                <div style="width: 100%">
                  <div class="userhead">
                    <img :src="item.box_record.user?.avatar" alt="" />
                  </div>
                  <div class="username">
                    {{ item.box_record.user?.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="londingbox" v-else>
            <div>
              <img src="../../assets/newImages/roll-londing.png" alt="" />
              <p>roll进行中，请等待开奖结果</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="input"
    >
      <CompPopup @close="closeinput">
        <div class="frombox">
          <div class="fromtitle">请输入密码</div>
          <div class="frominput">
            <div class="inputbox">
              <input
                class="put putc"
                type="password"
                placeholder="请输入密码"
                v-model="pwdvalue"
              />
            </div>
          </div>
          <div class="btnbox">
            <div class="btn" @click="JoinHome2()">确认</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
  </div>
</template>

<script>
import { RoomDetail, RoomJoin } from "../../network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      roomdata: {},
      cahngelist: [
        {
          id: 1,
          title: "参与用户",
        },
        {
          id: 2,
          title: "活动奖池",
        },
        {
          id: 3,
          title: "中奖名单",
        },
      ],
      acchangeindex: 1,
      input: false,
      pwdvalue: "",
    };
  },
  created() {
    this.getroomdetail();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    closeinput() {
      this.input = false;
      this.pwdvalue = "";
    },
    async joinroom() {
      if (this.roomdata.is_pwd != 0) {
        this.input = true;
      } else {
        let params = {
          id: this.$route.query.id,
        };

        const res = await RoomJoin(params);
        console.log(res);

        if (res.data.code == 200) {
          this.$message.success("加入成功");
          this.getroomdetail();
          this.input = false;
          this.pwdvalue = "";
        } else {
          this.$message.error(res.data.message);
          this.input = false;
          this.pwdvalue = "";
        }
      }
    },
    async JoinHome2() {
      if (this.pwdvalue == "") {
        this.$message.error("请输入密码");
      } else {
        let params = {
          id: this.$route.query.id,
          password: this.pwdvalue,
        };

        const res = await RoomJoin(params);
        if (res.data.code == 200) {
          this.$message.success("加入成功");
          this.getroomdetail();
          this.input = false;
          this.pwdvalue = "";
        } else {
          this.$message.error(res.data.message);
          this.input = false;
          this.pwdvalue = "";
        }
      }
    },
    changeclick(v) {
      this.acchangeindex = v;
    },
    async getroomdetail() {
      let params = {
        id: this.$route.query.id,
      };
      const res = await RoomDetail(params);
      console.log(res);
      this.roomdata = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.roomdetailbox {
  overflow: hidden;
  .center {
    width: 3.35rem;
    margin: 0.6rem auto 0;
    .topinfo {
      width: 100%;
      margin-bottom: 0.18rem;
      .roomid {
        margin-bottom: 0.19rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
      }
      .infocard {
        width: 100%;

        .tophead {
          display: flex;
          align-items: center;
          height: 0.5rem;
          margin-bottom: 0.19rem;
          .lbox {
            width: 0.5rem;
            height: 0.5rem;
            @include bgImg("user-headbg");
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.22rem;
            img {
              width: 0.47rem;
              height: 0.47rem;
              border-radius: 50%;
            }
          }
          .rbox {
            .infotext {
              font-weight: 500;
              font-size: 0.14rem;
              margin-bottom: 0.05rem;
              color: #ffffff;
            }
            .infonum {
              font-weight: 400;
              font-size: 0.12rem;
              color: #ffffff;
            }
          }
          .joinbtnbox {
            width: 55%;
            display: flex;
            justify-content: flex-end;
            .joinbtn {
              width: 0.7rem;
              height: 0.3rem;
              @include bgImg("search-btn-bg");
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 0.12rem;
              color: #000000;
            }
          }
        }
        .contenttext {
          width: 100%;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
        }
        .contenttime {
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-top: 0.12rem;
          margin-bottom: 0.18rem;
        }
      }
      .goodstips {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tipsitem {
          width: 1.6rem;
          height: 0.32rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          @include bgImg("price-change-bg");
        }
      }
    }
    .listbox {
      width: 100%;
      padding-bottom: 0.4rem;
      @include bgImg("goods-list-bg");
      .changetype {
        width: 100%;
        height: 0.36rem;
        @include bgImg("list-title-bg");
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: 500;
        font-size: 0.12rem;
        color: #bca375;
        .changeitem {
          width: 30%;
          text-align: center;
        }
        .acchangeitem {
          color: #ffffff;
        }
      }
      .userlist {
        display: flex;
        flex-wrap: wrap;
        .usersitem {
          width: 0.83rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.12rem;
          .userhead {
            width: 100%;
            height: 0.4rem;
            display: flex;
            justify-content: center;
            img {
              width: 0.4rem;
              height: 0.4rem;
              border-radius: 50%;
            }
          }
          .username {
            width: 100%;
            margin-top: 0.08rem;
            font-size: 0.12rem;
            color: #ffffff;
            text-align: center;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
        }
      }
      .goodslist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .goodsitem {
          width: 0.94rem;
          height: 1.1rem;
          border: 0.01rem solid #69552d;
          margin-top: 0.12rem;
          .goldbox {
            width: 100%;
            height: 100%;
            img {
              width: 98%;
            }
          }
          .goodstop {
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 0.1rem;
            margin-top: 0.06rem;
          }
          .imgbox {
            width: 0.8rem;
            height: 0.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            img {
              width: 100%;
            }
          }
          .goodsname {
            text-align: center;
            font-weight: 400;
            font-size: 0.1rem;
            color: #ffffff;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
          .pricebox {
            margin: 0.05rem 0;
          }
        }
      }
      .getgoodslist {
        width: 100%;
        .getgoodsitem {
          width: 3.1rem;
          height: 0.8rem;
          // border: 0.01rem solid #69552d;
          @include bgImg("itemlistbg");
          margin: 0.12rem auto 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lbox {
            width: 2.3rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 0.08rem;
            img {
              width: 0.75rem;
              margin-right: 0.1rem;
            }
            .goodsname {
              font-weight: 400;
              font-size: 0.1rem;
              color: #ffffff;
              margin-bottom: 0.06rem;
            }
            .goodsprice {
              display: flex;
              justify-content: flex-start;
            }
          }
          .rbox {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 0.12rem;
            .userhead {
              width: 0.36rem;
              height: 0.36rem;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .username {
              text-align: center;
              font-weight: 400;
              font-size: 0.1rem;
              color: #ffffff;
              margin-top: 0.1rem;
            }
          }
        }
        .isluckybox {
          @include bgImg("roll-luckyitem");
          width: 3.2rem;
          height: 1rem;
          padding: 0 0.01rem;
        }
        .londingbox {
          min-height: 40vh;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 0.22rem;
            height: 0.22rem;
            margin: 0 auto;
          }
          p {
            font-weight: 500;
            font-size: 0.12rem;
            color: #bca375;
            margin-top: 0.12rem;
          }
        }
      }
    }
  }
  .frombox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .fromtitle {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
      margin-bottom: 0.12rem;
    }
    .frominput {
      width: 2.75rem;
      margin: 0 auto;
      .inputbox {
        width: 100%;
        margin-bottom: 0.16rem;
        position: relative;
        .inputtitle {
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-bottom: 0.08rem;
        }
        .put {
          width: 100%;
          height: 0.38rem;
          background: #413f3e;
          text-decoration: none;
          border: 0;
          font-weight: 400;
          font-size: 0.14rem;
          color: #999999;
          text-indent: 0.12rem;
        }
        .putc {
          text-indent: unset;
          text-align: center;
          line-height: 0.38rem;
        }
      }
    }
    .btnbox {
      width: 100%;
      margin-top: 0.12rem;
      .btn {
        width: 1.4rem;
        height: 0.42rem;
        background: url("../../assets/newImages/user-sbtn.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.18rem;
        color: #312f2d;
        margin: 0 auto;
        font-family: PingFang SC, PingFang SC;
      }
    }
  }
}
</style>
